import type { MouseEventHandler } from 'react';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import LoginIcon from '@atlaskit/icon/core/log-in';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { UserAction } from '@atlassian/navigation-system/top-nav';

import { START_TOUCH } from '@confluence/navdex';
import { useRouteDataRef, useRouteActions } from '@confluence/route-manager';
import { CONTEXT_PATH } from '@confluence/named-routes';

const LOGIN_URL =
	typeof window !== 'undefined'
		? `${CONTEXT_PATH}/login.action?os_destination=${window.location.pathname.replace(
				CONTEXT_PATH,
				'',
			)}`
		: '';

export const LoginButton = () => {
	const routeDataRef = useRouteDataRef();
	const { push } = useRouteActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick: MouseEventHandler = (e) => {
		if (e.metaKey) {
			window.open(LOGIN_URL, '_blank', 'noreferrer');
		} else {
			push(LOGIN_URL);
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'globalNavigation',
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'login',
				attributes: {
					selectedItemPageContext: routeDataRef.current?.routeName,
					navigationLayer: 'global',
					navVersion: '4',
					navdexPointType: START_TOUCH,
				},
			},
		}).fire();
	};

	return (
		<UserAction icon={LoginIcon} onClick={onClick}>
			<FormattedMessage {...i18n.login} />
		</UserAction>
	);
};

const i18n = defineMessages({
	login: {
		id: 'app-navigation.top-navigation.login',
		defaultMessage: 'Log in',
		description: 'Label for the top navigation login button',
	},
});
