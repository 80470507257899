import React from 'react';

import {
	ConversationAssistantNavBarButton,
	RovoEntitlementSetter,
} from '@confluence/conversation-assistant';
import { useSessionData } from '@confluence/session-data';

export const RovoButton = () => {
	const { isLoggedIn, isRovoEnabled: isRovoEnabledSessionData } = useSessionData();

	const isRovoEnabled = isRovoEnabledSessionData && isLoggedIn;

	return (
		<>
			{isRovoEnabled && <ConversationAssistantNavBarButton />}
			<RovoEntitlementSetter isRovoEnabled={isRovoEnabled} />
		</>
	);
};
