// ANALYTICS PROPERTIES
const SPACE_OVERVIEW = 'spaceSettings/overview';
const SPACE_PERMISSIONS = 'spaceSettings/permissions';
const SPACE_CONTENT_TOOLS = 'spaceSettings/contentTools';
const SPACE_LOOK_AND_FEEL = 'spaceSettings/lookAndFeel';
const SPACE_INTEGRATIONS = 'spaceSettings/Integrations';

// ROUTE GROUPS
const SPACE_SETTINGS = 'space-settings';

// NOTICE AT COLLECTION LINKS
export const INTERNAL_NOTICE_AT_COLLECTION_LINK =
	'https://hello.atlassian.net/wiki/spaces/PMP/pages/139161942/Standard+-+Global+Workplace+Privacy+Notice#13.-Additional-Disclosures-for-California-Residents';
export const EXTERNAL_NOTICE_AT_COLLECTION_LINK =
	'https://www.atlassian.com/legal/privacy-policy#additional-disclosures-for-ca-residents';

// PRIVACY POLICY LINKS
export const INTERNAL_PRIVACY_POLICY_LINK =
	'https://hello.atlassian.net/wiki/spaces/PMP/pages/139161942/Standard+-+Global+Workplace+Privacy+Notice';
export const EXTERNAL_PRIVACY_POLICY_LINK =
	'https://www.atlassian.com/legal/privacy-policy#what-this-policy-covers';

// FILTERED DEFAULT HELP ITEMS
export const FILTERED_MENU_ITEMS = [
	'Online Help',
	'Get the mobile app',
	'Feed Builder',
	'Keyboard Shortcuts',
	'Site Status',
	'What’s new',
	'About Confluence',
];

export const getRouteData = (route: string) => {
	// get what route we're on by switching based on
	// the regex evaluating to true
	switch (true) {
		/**
		 * SPACE SETTINGS - OVERVIEW
		 */
		case /viewspacesummary/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'space-details',
				articleId: '2ngx6W7OSkTdvFP6m1jdYC',
				section: SPACE_OVERVIEW,
				screen: 'spaceDetailsScreen',
			};
		case /archivespace/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'archive-space',
				articleId: '18AcZoC9QhZZ0lzuMBaZQD',
				section: SPACE_OVERVIEW,
				screen: 'archiveSpaceScreen',
			};
		case /archiveSpace/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'archive-space',
				articleId: '18AcZoC9QhZZ0lzuMBaZQD',
				section: SPACE_OVERVIEW,
				screen: 'archiveSpaceScreen',
			};
		case /editFeatures/.test(route):
			return {
				routeName: 'edit-sidebar',
				routeGroup: SPACE_SETTINGS,
				articleId: '6ivofSJlfSXa9IASo00kvA',
				section: SPACE_OVERVIEW,
				screen: 'sidebarConfigurationScreen',
			};
		case /removespace/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'delete-space',
				articleId: '7edCGN0nkqsvUd9s3AzEq8',
				section: SPACE_OVERVIEW,
				screen: 'deleteSpaceScreen',
			};
		/**
		 * SPACE SETTINGS - PERMISSIONS
		 */
		case /spaces\/.*\/settings\/permissions\/\d*/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'space-permissions',
				articleId: '6fQGHt1G8f5KUxXTinTVvW',
				section: SPACE_PERMISSIONS,
				screen: 'permissionsScreen',
			};
		case /spacepermissions/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'space-permissions',
				articleId: '6fQGHt1G8f5KUxXTinTVvW',
				section: SPACE_PERMISSIONS,
				screen: 'permissionsScreen',
			};
		case /addonpermissions/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'analytics-permissions',
				articleId: '',
				section: SPACE_PERMISSIONS,
				screen: 'addOnScreen',
			};
		case /listpermissionpages/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'page-restrictions',
				articleId: '7f9j14trnF75aeuihHAoLp',
				section: SPACE_PERMISSIONS,
				screen: 'restrictedPagesScreen',
			};
		case /reorderpages/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'reorder-pages',
				articleId: '5faOvjH2hAw2CpZY3tC3ej',
				section: SPACE_CONTENT_TOOLS,
				screen: 'reorderPagesScreen',
			};
		case /listorphanedpages/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'orphaned-pages',
				articleId: '1mxG2f1dvIifiDqKrkvy98',
				section: SPACE_CONTENT_TOOLS,
				screen: 'orphanedPagesScreen',
			};
		case /listundefinedpages/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'undefined-pages',
				articleId: '',
				section: SPACE_CONTENT_TOOLS,
				screen: 'undefinedPagesScreen',
			};
		case /listattachmentsforspace/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'attachments',
				articleId: '',
				section: SPACE_CONTENT_TOOLS,
				screen: 'attachmentsScreen',
			};
		case /viewtrash/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'trash',
				articleId: '6NL5cRC6aV7k9ucd2yEZzZ',
				section: SPACE_CONTENT_TOOLS,
				screen: 'trashScreen',
			};
		case /exportspacewelcome/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'export-space',
				articleId: '6RcXthpHKQasH09gERDkE8',
				section: SPACE_CONTENT_TOOLS,
				screen: 'exportScreen',
			};
		case /listrssfeeds/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'rss-feed',
				articleId: '6TyTkhaowcNTwvq9V0rt10',
				section: SPACE_CONTENT_TOOLS,
				screen: 'rssFeedsScreen',
			};
		/**
		 * SPACE SETTINGS - LOOK AND FEEL
		 */
		case /spaces\/choosetheme/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'themes',
				articleId: '3Owkyfl2mshKQQ2mECCV8q',
				section: SPACE_LOOK_AND_FEEL,
				screen: 'themesScreen',
			};
		case /custompagecontent/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'header-and-footer',
				articleId: '2i2NAHbTjkWeoprrdWlNX',
				section: SPACE_LOOK_AND_FEEL,
				screen: 'headerAndFooterScreen',
			};
		case /viewpdfconfig/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'undefined',
				articleId: '',
				section: SPACE_LOOK_AND_FEEL,
				screen: 'pdfConfigScreen',
			};
		case /viewpdflayoutconfig/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'undefined',
				articleId: '',
				section: SPACE_LOOK_AND_FEEL,
				screen: 'pdfLayoutScreen',
			};
		case /viewpdfstyleconfig/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'undefined',
				articleId: '',
				section: SPACE_LOOK_AND_FEEL,
				screen: 'pdfStylesheetScreen',
			};
		/**
		 * SPACE SETTINGS - INTEGRATIONS
		 */
		case /listentitylinks/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'undefined',
				articleId: '',
				section: SPACE_INTEGRATIONS,
				screen: 'applicationLinksScreen',
			};
		/**
		 * CONNECT ADDONS
		 */
		case /analytics\/permissions/.test(route):
		//TODO - CCIR-11: Clean up the following case once the native Analytics UI migration is complete
		case /analytics-permissions-instance-admin-page/.test(route):
			return {
				routeGroup: 'site-settings',
				routeName: 'analytics-permissions',
				articleId: '6yKVtN1tSSrOHTp4TpXtkg',
				section: 'notTracked',
				screen: 'notTracked',
			};
		case /atlassian-connect\/space-tools-tab.action/.test(route):
			return {
				routeGroup: SPACE_SETTINGS,
				routeName: 'analytics-permissions',
				articleId: '6yKVtN1tSSrOHTp4TpXtkg',
				section: SPACE_PERMISSIONS,
				screen: 'analyticsPermissionsScreen',
			};
		/**
		 * CONTENT TYPE VIEW PAGES
		 */
		case /spaces\/.*\/pages\/\d*/.test(route):
			return {
				routeGroup: 'space',
				routeName: 'view-page',
				articleId: '',
				section: 'notTracked',
				screen: 'notTracked',
			};
		case /spaces\/.*\/whiteboard\/\d*/.test(route):
			return {
				routeGroup: 'space',
				routeName: 'view-whiteboard',
				articleId: '',
				section: 'notTracked',
				screen: 'notTracked',
			};
		case /spaces\/.*\/database\/\d*/.test(route):
			return {
				routeGroup: 'space',
				routeName: 'view-database',
				articleId: '',
				section: 'notTracked',
				screen: 'notTracked',
			};
		case /spaces\/.*\/embed\/\d*/.test(route):
			return {
				routeGroup: 'space',
				routeName: 'view-embed',
				articleId: '',
				section: 'notTracked',
				screen: 'notTracked',
			};
		case /spaces\/.*\/calendars/.test(route):
			return {
				routeGroup: 'space',
				routeName: 'view-calendars',
				articleId: '85KAXe75f9VdMibJa5FHz',
				section: 'notTracked',
				screen: 'notTracked',
			};
		/**
		 * EVERYTHING ELSE
		 */
		default:
			return {
				routeGroup: 'undefined',
				articleId: '',
				section: 'notTracked',
				routeName: 'undefined',
				screen: 'notTracked',
			};
	}
};

export const isAlgoliaUnreachableError = (error: string) => {
	return ['algolia', 'unreachable hosts'].some((err) => error.toLowerCase().includes(err));
};
