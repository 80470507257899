import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { mergeRefs } from 'use-callback-ref';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Popup from '@atlaskit/popup';
import { NotificationIndicator } from '@atlaskit/notification-indicator';
import { NotificationLogClient } from '@atlaskit/notification-log-client';

import { Notifications } from '@atlassian/navigation-system/top-nav';

import {
	GeneralShortcutListener,
	ShortcutVisualizer,
	NOTIFICATIONS_SHORTCUT,
	NOTIFICATIONS_SHORTCUT_IN_EDIT,
} from '@confluence/shortcuts';
import { useSessionData, AccessStatus } from '@confluence/session-data';
import { useRouteDataRef } from '@confluence/route-manager';
import { LoadableLazy } from '@confluence/loadable';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';

const fabricNotificationLogUrl = '/gateway/api/notification-log';

const REFRESH_INTERVAL = 60000;
const CLEAR_BADGE_TIME = 10000;
const MAX_NOTIFICATION_COUNT = 9;

export const NotificationsContentLoader = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-NotificationsContent" */ './NotificationsContent'))
			.NotificationsContent,
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireNotificationsClickedAnalytics,
);

class EmptyNotificationLogClient extends NotificationLogClient {
	constructor() {
		super('', '');
	}

	countUnseenNotifications() {
		return Promise.resolve({ count: 0 });
	}
}

const useNotificationLogClient = () => {
	return useMemo(() => new NotificationLogClient(fabricNotificationLogUrl, undefined), []);
};

export const NotificationsButton = () => {
	const notificationsButtonRef = useRef<HTMLButtonElement | null>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [isCleared, setIsCleared] = useState(false);
	const [unreadCount, setUnreadCount] = useState(0);
	const { accessStatus } = useSessionData();
	const isLicensed =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const routeDataRef = useRouteDataRef();
	const intl = useIntl();
	const notificationLogClient = useNotificationLogClient();
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();

	const fireClickedAnalytics = useLazyClickAnalytics(
		createAnalyticsEvent,
		routeDataRef.current?.routeName,
	);

	useEffect(() => {
		if (isCleared) {
			const timeout = setTimeout(() => {
				setIsCleared(false);
			}, CLEAR_BADGE_TIME);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [isCleared]);

	const onClick = () => {
		if (isOpen) {
			onClose();
		} else {
			performance.mark('notification.list.render.start');
			setIsCleared(true);
			setIsOpen(true);
		}

		void fireClickedAnalytics();
	};

	const onClose = useCallback(() => {
		notificationsButtonRef.current?.focus();
		setIsOpen(false);
		performance.clearMarks('notification.list.render.start');
	}, []);

	useEffect(() => {
		notificationLogClient
			.countUnseenNotifications()
			.then((res) => {
				setUnreadCount(res.count);
				if (res.count >= 9) {
					createAnalyticsEvent({
						type: 'sendScreenEvent',
						data: {
							name: 'unseenNotificationsOver9',
						},
					}).fire();
				}
			})
			.catch(() => {});
	}, [createAnalyticsEvent, notificationLogClient]);

	const NotificationsBadge = useMemo(
		() => () => {
			return (
				<NotificationIndicator
					onCountUpdated={(count) => {
						setUnreadCount(count.newCount);
					}}
					notificationLogProvider={Promise.resolve(
						isCleared || isOpen ? new EmptyNotificationLogClient() : notificationLogClient,
					)}
					refreshRate={REFRESH_INTERVAL}
				/>
			);
		},
		[isOpen, isCleared, notificationLogClient],
	);

	const notificationsLabel =
		unreadCount <= MAX_NOTIFICATION_COUNT
			? intl.formatMessage(i18n.notifications, { count: unreadCount })
			: intl.formatMessage(i18n.overNineNotifications);

	return !isLicensed ? null : (
		<>
			<Popup
				placement="bottom-end"
				shouldFlip
				rootBoundary="viewport"
				content={() => <NotificationsContentLoader />}
				isOpen={isOpen}
				onClose={onClose}
				shouldRenderToParent
				trigger={(triggerProps) => (
					<Notifications
						{...triggerProps}
						badge={NotificationsBadge}
						onClick={onClick}
						isSelected={isOpen}
						label={
							<ShortcutVisualizer
								shortcut={NOTIFICATIONS_SHORTCUT}
								contentBefore={notificationsLabel}
							/>
						}
						ref={mergeRefs([triggerProps.ref, notificationsButtonRef])}
					/>
				)}
			/>
			<GeneralShortcutListener
				accelerator={
					isLiveEditMode && fg('confluence_frontend_live_edit_keyboard_shortcut')
						? NOTIFICATIONS_SHORTCUT_IN_EDIT
						: NOTIFICATIONS_SHORTCUT
				}
				listener={onClick}
				isAppNavigationShortcut
			/>
		</>
	);
};

const i18n = defineMessages({
	notifications: {
		id: 'app-navigation.top-navigation.notifications',
		defaultMessage:
			'{count, plural, =0{ Notifications} one{{count, number} Notification} other{{count, number} Notifications}}',
		description: 'Button label for the top navigation notifications button.',
	},
	overNineNotifications: {
		id: 'app-navigation.top-navigation.notifications.over-nine-notifications',
		defaultMessage: 'Over 9 Notifications',
		description: 'Label for the notification count when there are more than 9 notifications',
	},
});
