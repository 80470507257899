import type { MouseEventHandler } from 'react';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import SettingsIcon from '@atlaskit/icon/core/settings';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { UserAction } from '@atlassian/navigation-system/top-nav';

import { START_TOUCH } from '@confluence/navdex';
import { useRouteDataRef, useRouteActions } from '@confluence/route-manager';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { ADMIN_SETTINGS } from '@confluence/named-routes';

export const SettingsButton = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const routeData = useRouteDataRef();
	const { push } = useRouteActions();

	const onClick: MouseEventHandler = (e) => {
		const url = ADMIN_SETTINGS.toUrl();

		if (e.metaKey) {
			window.open(url, '_blank', 'noreferrer');
		} else {
			push(url);
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'globalNavigation',
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'settings',
				attributes: {
					selectedItemPageContext: routeData.current?.routeName,
					navigationLayer: 'global',
					navVersion: '4',
					navdexPointType: START_TOUCH,
				},
			},
		}).fire();
	};

	const { accessStatus } = useSessionData();
	const isAdmin = accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS;

	return isAdmin ? (
		<UserAction icon={SettingsIcon} onClick={onClick}>
			<FormattedMessage {...i18n.settings} />
		</UserAction>
	) : null;
};

const i18n = defineMessages({
	settings: {
		id: 'app-navigation.top-navigation.settings',
		defaultMessage: 'Confluence administration',
		description: 'Label for the top navigation global admin settings button',
	},
});
