import React, { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useIntl, defineMessages } from 'react-intl-next';

import { SpotlightTarget } from '@atlaskit/onboarding';

import { HomeActions, CommonActions, UserActions } from '@atlassian/navigation-system/top-nav';
import { SideNavToggleButton } from '@atlassian/navigation-system/layout/side-nav';

import { useSessionData } from '@confluence/session-data';
import { ErrorDisplay } from '@confluence/error-boundary';
import { APP_NAV_GLOBAL_EXPERIENCE, ExperienceSuccess } from '@confluence/experience-tracker';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { APP_NAVIGATION_METRIC } from '@confluence/browser-metrics/entry-points/app-navigation.metric';
import {
	PerformanceEnd,
	PERFORMANCE_SUBJECT_navigation,
	PERFORMANCE_SUBJECT_navigationFMP,
} from '@confluence/performance';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import {
	ShortcutVisualizer,
	SIDE_NAVIGATION_SHORTCUT,
	SIDE_NAVIGATION_SHORTCUT_IN_EDIT,
} from '@confluence/shortcuts';
import { EditionAwareness } from '@confluence/edition-awareness';

import { TopNavigationQuery } from './TopNavigationQuery.graphql';
import type { TopNavigationQuery as QueryData } from './__types__/TopNavigationQuery';
import { AppSwitcherButton } from './AppSwitcherButton';
import { DeveloperButtons } from './DeveloperButtons';
import { SiteLogoButton } from './SiteLogoButton';
import { HelpButton } from './HelpButton';
import { NotificationsButton } from './NotificationsButton';
import { SettingsButton } from './SettingsButton';
import { ProfileButton } from './ProfileButton';
import { LoginButton } from './LoginButton';
import { CreateComponent } from './CreateComponent';
import { SearchBar } from './SearchBar';
import { RovoButton } from './RovoButton';

export const TopNavigation = ({ initialCollapsedState }) => {
	const { data, loading, error } = useQuery<QueryData>(TopNavigationQuery);

	if (loading) {
		return null;
	}

	return (
		<>
			<TopNavigationInner data={data} initialCollapsedState={initialCollapsedState} />
			{error && <ErrorDisplay error={error} />}
		</>
	);
};

type TopNavigationInnerProps = {
	data: QueryData | undefined;
	initialCollapsedState: boolean;
};

const TopNavigationInner = ({ data, initialCollapsedState }: TopNavigationInnerProps) => {
	const { isLoggedIn } = useSessionData();
	const { formatMessage } = useIntl();
	const isOnEditRoute = useIsEditorPage();

	const getShortcutVisualizer = useCallback(
		(label: string) => (
			<ShortcutVisualizer
				contentBefore={label}
				shortcut={isOnEditRoute ? SIDE_NAVIGATION_SHORTCUT_IN_EDIT : SIDE_NAVIGATION_SHORTCUT}
				isEditorShortcut={isOnEditRoute}
			/>
		),
		[isOnEditRoute],
	);

	return (
		<>
			<HomeActions>
				<SpotlightTarget name="nav4-spotlight-1">
					<SideNavToggleButton
						defaultCollapsed={initialCollapsedState}
						collapseLabel={getShortcutVisualizer(
							formatMessage(i18n.sideNavToggleButtonCollapseLabel),
						)}
						expandLabel={getShortcutVisualizer(formatMessage(i18n.sideNavToggleButtonExpandLabel))}
					/>
				</SpotlightTarget>
				<AppSwitcherButton />
				<DeveloperButtons />
				<SiteLogoButton siteConfiguration={data?.siteConfiguration} />
			</HomeActions>
			<CommonActions>
				<SearchBar />
				<CreateComponent />
			</CommonActions>
			<UserActions>
				<EditionAwareness />
				<RovoButton />
				<NotificationsButton />
				<HelpButton />
				<SettingsButton />
				{isLoggedIn ? <ProfileButton user={data?.user} /> : <LoginButton />}
			</UserActions>
			<ExperienceSuccess name={APP_NAV_GLOBAL_EXPERIENCE} attributes={{ navVersion: '4' }} />
			<PageSegmentLoadEnd
				metric={APP_NAVIGATION_METRIC}
				customData={{
					isAnonymous: !isLoggedIn,
				}}
			/>
			<PerformanceEnd
				subject={PERFORMANCE_SUBJECT_navigation}
				subjectId="NavigationLoading"
				includeFeatureFlags
				details={nav4Details}
			/>
			<PerformanceEnd
				subject={PERFORMANCE_SUBJECT_navigationFMP}
				subjectId="NavigationFMP"
				includeFeatureFlags
				details={nav4Details}
			/>
		</>
	);
};

const i18n = defineMessages({
	sideNavToggleButtonCollapseLabel: {
		id: 'app-navigation.top-navigation.side-nav-toggle-button-collapse-label',
		defaultMessage: 'Collapse sidebar',
		description:
			'Label to use for side nav toggle button when it will collapse the side bar when clicked. It ends with a bracket character that reflects the keyboard shortcut that will toggle the side bar.',
	},
	sideNavToggleButtonExpandLabel: {
		id: 'app-navigation.top-navigation.side-nav-toggle-button-expand-label',
		defaultMessage: 'Expand sidebar',
		description:
			'Label to use for side nav toggle button when it will expand the side bar when clicked. It ends with a bracket character that reflects the keyboard shortcut that will toggle the side bar.',
	},
});

const nav4Details = { navVersion: '4' };
