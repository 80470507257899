import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { NavigationSearchDialog } from '@confluence/navigation-search-dialog';

export const SearchBar = () => (
	<Box xcss={searchBarWrapper}>
		<NavigationSearchDialog shouldFillContainer />
	</Box>
);

const searchBarWrapper = xcss({
	position: 'relative',
	width: '100%',
	height: '32px',
	maxWidth: '780px',
});
