import type { MutableRefObject } from 'react';
import { createStore, createHook } from 'react-sweet-state';

type TopNavigationState = {
	profileMenuButtonRef?: MutableRefObject<HTMLButtonElement | null>;
};

const initialState: TopNavigationState = {
	profileMenuButtonRef: { current: null },
};

export const actions = {
	setProfileMenuButtonRef:
		(value: HTMLButtonElement | null) =>
		({ getState }: { getState: () => TopNavigationState }) => {
			const { profileMenuButtonRef } = getState();
			if (profileMenuButtonRef) {
				profileMenuButtonRef.current = value;
			}
		},
};

export const topNavigationStore = createStore({
	initialState,
	actions,
	name: 'topNavigationStore',
});

export const useTopNavigationStore = createHook(topNavigationStore);
