import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import AtlassianSwitcher from '@atlassian/switcher';

import { useSessionData } from '@confluence/session-data';

const maxHeightWrapper = xcss({
	maxHeight: 'calc(100vh - 100px)',
});

export const AppSwitcherContent = () => {
	const { cloudId } = useSessionData();

	return (
		<Box xcss={maxHeightWrapper}>
			<AtlassianSwitcher product="confluence" cloudId={cloudId} appearance="standalone" />
		</Box>
	);
};
